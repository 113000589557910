import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ToastsContainer } from './components/toasts/toasts.component';

@NgModule({
  imports: [CommonModule, NgbToastModule],
  exports: [ToastsContainer, AlertModalComponent],
  declarations: [ToastsContainer, AlertModalComponent],
  providers: [],
})
export class CommonCoreModule {}
