<div class="chatbot-container" *ngIf="isAuthenticated">
  <div class="bg" (click)="open()">
    <tui-svg class="icon" src="tuiIconCommentLarge"></tui-svg>
  </div>
</div>

<ng-template #templateModal let-observer>
  <app-chatbot></app-chatbot>
</ng-template>

<ng-template #header>
  <div class="chatbot-header w-100">
    <div class="row m-0">
      <div class="icon-cnt ms-3 my-2 col-2">
        <tui-svg class="icon" src="tuiIconCommentLarge"></tui-svg>
      </div>
      <div class="col-10 my-auto">
        <h6 class="m-0 fw-bold">ChatBot InPlace</h6>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</ng-template>
