import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ChatbotService } from './chatbot.service';
declare let window: any;

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
})
export class ChatbotComponent implements OnInit, AfterViewInit {
  @ViewChild('webchatbot', { static: true }) webChatbotContainer!: ElementRef;

  constructor(private chatbotService: ChatbotService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    console.log(
      'window.WebChat',
      window.WebChat,
      this.webChatbotContainer.nativeElement
    );
    this.chatbotService.generateWebChatToken().subscribe((response: any) => {
      window.WebChat.renderWebChat(
        {
          directLine: window.WebChat.createDirectLine({
            token: response.token,
          }),
          userID: Date.now().toString(),
          username: 'user123',
          locale: 'it-IT',
          styleOptions: avatarOptions,
        },
        this.webChatbotContainer.nativeElement
      );
    });

    const avatarOptions = {
      botAvatarInitials: ' ',
      userAvatarInitials: ' ',
    };
  }
}
