<div class="login-overlay d-flex flex-column">
  <div class="row mt-3 justify-content-center">
    <div class="login mb-4">
      <div class="row mb-3">
        <h2>{{ "LOGIN.LOGIN" | translate }}</h2>
        <h5 style="white-space: pre-line">
          {{ "LOGIN.LOGIN_TEXT" | translate }}
        </h5>
        <div class="form-responsive single-col">
          <div class="form-group">
            <app-input-label
              [field]="loginForm.get('email')"
              [name]="loginForm.get('email').label"
              [filter]="false"></app-input-label>
            <app-textbox-input
              [control]="loginForm.get('email') | castTextInput"
              [name]="loginForm.get('email').label"></app-textbox-input>
            <app-validation-input-message
              [statusMessage]="loginForm.get('email').statusMessage"></app-validation-input-message>
          </div>
          <div class="form-group">
            <app-input-label
              [field]="loginForm.get('password')"
              [name]="loginForm.get('password').label"
              [filter]="false"
            ></app-input-label>
            <tui-input
              [formControl]="loginForm.get('password')"
              [tuiTextfieldLabelOutside]="true">
              <input
                tuiTextfield
                [type]="hide ? 'password' : 'text'"
                [placeholder]="loginForm.get('password').placeholder | translate"
              />
            </tui-input>
            <app-validation-input-message
              [statusMessage]="loginForm.get('password').statusMessage"></app-validation-input-message>
            <div class="row m-0">
              <div
                class="col-6 d-block mt-2 text-primary fw-bolder cursor-pointer"
                *ngIf="loginForm.get('email')?.value"
                (click)="forgotPassword()">
                {{ "LOGIN.FORGOT_PASSWORD.LABEL" | translate }}
              </div>
              <div class="col-6" *ngIf="!loginForm.get('email')?.value">
                &nbsp;
              </div>
              <div
                class="col-6 d-block mt-2 text-primary fw-bolder cursor-pointer text-end"
                *ngIf="loginForm.get('password')?.value && hide"
                (click)="passwordToogle()">
                {{ "LOGIN.SHOW_PASSWORD" | translate }}
              </div>
              <div
                class="col-6 d-block mt-2 text-primary fw-bolder cursor-pointer text-end"
                *ngIf="loginForm.get('password')?.value && !hide"
                (click)="passwordToogle()">
                {{ "LOGIN.HIDE_PASSWORD" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button
          [disabled]="!loginForm.valid"
          (click)="onLogin()"
          class="btn btn-green text-uppercase">
          {{ "UTILS.LOGIN" | translate }}
        </button>
      </div>
    </div>

    <!-- SOCIAL LOGIN -->
    <div class="social-container my-4" *ngIf="userAgent != UserAgent.FLUTTER_WEB_VIEW">
      <div class="my-4 border-top"></div>
      <div class="row mb-2">
        <div class="text-center d-flex justify-content-center">
          <span class="social-login">
            {{ "LOGIN.SOCIAL.ARE_YOU" | translate }}
            <span class="dark-green-text">
              <strong>{{ "LOGIN.SOCIAL.WORKER" | translate }}</strong>
            </span>
            {{ "LOGIN.SOCIAL.REGISTER" | translate }}
            <span class="dark-green-text">
              <strong>{{ "LOGIN.SOCIAL.SOCIAL" | translate }}</strong>
            </span>
          </span>
        </div>
      </div>
      <div class="social">
        <div class="row text-center align-items-center m-0">
          <div class="col-4">
            <button
              class="btn btn-social mb-2 w-100"
              (click)="socialLogin(ProviderEnum.GOOGLE)">
              <div class="row justify-content-center">
                <div class="col-12">
                  <img
                    height="30"
                    class="cursor-pointer"
                    src="./assets/images/google.png" />
                </div>
                <div class="col-10 pt-1 fw-bold no-mobile">
                  {{ "LOGIN.SOCIAL.GOOGLE" | translate }}
                </div>
              </div>
            </button>
          </div>
          <div class="col-4">
            <button
              class="btn btn-social mb-2 w-100"
              (click)="socialLogin(ProviderEnum.FACEBOOK)">
              <div class="row justify-content-center">
                <div class="col-12">
                  <img
                    height="30"
                    class="cursor-pointer"
                    src="./assets/images/facebook.png" />
                </div>
                <div class="col-10 pt-1 fw-bold no-mobile">
                  {{ "LOGIN.SOCIAL.FACEBOOK" | translate }}
                </div>
              </div>
            </button>
          </div>
          <!-- TODO: show apple login; social login col-4 -> col-6-->
          <!-- <div class="col-6">
            <button
              class="btn btn-social mb-2 w-100"
              (click)="socialLogin(ProviderEnum.APPLE)">
              <div class="row justify-content-center">
                <div class="col-12 col-lg-2">
                  <img
                    height="30"
                    class="cursor-pointer"
                    src="./assets/images/apple.png" />
                </div>
                <div class="col-10 pt-1 fw-bold no-mobile">
                  {{ "LOGIN.SOCIAL.APPLE" | translate }}
                </div>
              </div>
            </button>
          </div> -->
          <div class="col-4">
            <button
              class="btn btn-social mb-2 w-100"
              (click)="authService.LinkedInAuth()">
              <div class="row justify-content-center">
                <div class="col-12">
                  <img
                    height="30"
                    class="cursor-pointer"
                    src="./assets/images/linkedin.png" />
                </div>
                <div class="col-10 pt-1 fw-bold no-mobile">
                  {{ "LOGIN.SOCIAL.LINKEDIN" | translate }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="mt-4 border-top"></div>
    </div>


    <!-- REGISTER -->
    <div class="register">
      <div class="d-flex flex-column justify-content-center my-4">
        <h4>{{ "LOGIN.NOT_REGISTRED_YET" | translate }}</h4>
        <h6>{{ "LOGIN.REGISTER_NOW" | translate }}</h6>
        <div
          class="d-flex flex-wrap justify-content-center align-items-center gap-3">
          <button
            (click)="onRegisterAsCandidate()"
            class="btn btn-green-outline text-uppercase mt-4">
            {{ "LOGIN.REGISTER_WORKER" | translate }}
          </button>
          <button
            (click)="onRegisterAsCompany()"
            class="btn btn-green-outline text-uppercase mt-4">
            {{ "LOGIN.REGISTER_COMPANY" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>