import { TitleCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  PipeTransform,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TextInput } from '../../../models/inputs/text-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@UntilDestroy()
@Component({
  selector: 'app-textbox-input-data-list',
  template: `
    <input
      id="{{ 'input' + name }}"
      [readOnly]="control.readonly"
      [tuiTextfieldLabelOutside]="true"
      [formControl]="formControl"
      [type]="control.type == 'number' ? 'text' : control.type"
      [placeholder]="control.placeholder | translate"]
    >
      <ng-template
        tuiDataList
        *ngIf="dataList.length && formControl.value.length > 2"
      >
        <tui-data-list>
          <button
            *ngFor="let item of dataList"
            (click)="onClick(item)"
            tuiOption
          >
            {{ item.des | titlecase }}
          </button>
        </tui-data-list>
      </ng-template>

      <ng-template
        tuiDataList
        *ngIf="
          formControl.value.length > 2 && !dataList.length && !control.value
        "
      >
        <tui-data-list>
          <button tuiOption>Nessun risultato</button>
        </tui-data-list>
      </ng-template>
  `,
})
export class TextboxInputDataListComponent
  extends BaseInputComponent
  implements OnInit
{
  constructor(private titleCasePipe: TitleCasePipe) {
    super();
  }

  @Input() name: string;
  @Input() control: TextInput;
  @Input() dataList: any[];
  @Input() initialValue!: string;
  @Output() query = new EventEmitter<string>();
  formControl = new UntypedFormControl('');

  ngOnInit() {
    if (this.initialValue) {
      this.formControl.patchValue(
        this.titleCasePipe.transform(this.initialValue),
        { emitEvent: false }
      );
    }

    this.formControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((change) => {
        if (!change) this.onClick({ cod: '', des: '' });
        this.query.emit(change);
      });
  }

  onClick(item: { cod: string; des: string }) {
    this.dataList = [];
    this.control.patchValue(item.cod);
    this.formControl.patchValue(this.titleCasePipe.transform(item.des), {
      emitEvent: false,
    });
  }
}
