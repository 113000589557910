export const ICON_TYPE = {
  offer: {
    icon: 'tuiIconFile',
    path: '/documenti',
  },
  annunci: {
    icon: 'tuiIconFolder',
    path: '/annunci',
  },
  proposal: {
    icon: 'tuiIconMail',
    path: '/availability',
  },
  profile: {
    icon: 'tuiIconUser',
    path: '/utente',
  },
  contract: {
    icon: 'tuiIconClipboard',
    path: '/documenti/contract',
  },
  conversation: {
    icon: 'tuiIconSend',
    path: '/conversations'
  },
  interview: {
    icon: 'tuiIconVideoLarge',
    path: '/interviews'
  }
};
