import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiGroupModule,
  TuiLabelModule,
  TuiLoaderModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiTooltipModule,
} from '@taiga-ui/core';
import {
  TuiCheckboxLabeledModule,
  TuiComboBoxModule,
  TuiDataListWrapperModule,
  TuiFilterByInputPipeModule,
  TuiFilterModule,
  TuiInputDateModule,
  TuiInputDateTimeModule,
  TuiInputFilesModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputTagModule,
  TuiInputTimeModule,
  TuiMultiSelectModule,
  TuiRadioBlockModule,
  TuiRadioLabeledModule,
  TuiRadioListModule,
  TuiRadioModule,
  TuiSelectModule,
  TuiSelectOptionModule,
  TuiSliderModule,
  TuiTextareaModule,
  TuiToggleModule,
} from '@taiga-ui/kit';
import { ToggleInputComponent } from 'src/app/shared/form/components/inputs/toggle-input/toggle-input.component';
import { ThemeModule } from 'src/app/theme/theme.module';
import { CastInputsPipesModule } from './cast-inputs-pipes.module';
import {
  AutocompleteInputComponent,
  CheckboxInputComponent,
  DateInputComponent,
  FormComponent,
  FormInputComponent,
  FormInputsComponent,
  RadiosInputComponent,
  SelectInputComponent,
  SelectVirtualInputComponent,
  TextareaInputComponent,
  TextboxInputComponent,
  TextboxInputDataListComponent,
  ValidationInputMessagesComponent,
} from './components';
import { InputLabelComponent } from './components/input-label/input-label.component';
import { DateInputTimeComponent } from './components/inputs/date-input-time/date-input-time.component';
import { FileInputComponent } from './components/inputs/file-input/file-input.component';
import { FilterButtonsInputComponent } from './components/inputs/filter-buttons-input/filter-buttons-input.component';
import { NopInputComponent } from './components/inputs/nop-input/nop-input.component';
import { SliderInputComponent } from './components/inputs/slider-input/slider-input.component';
import { TagInputComponent } from './components/inputs/tag-input/tag-input.component';
import { TextboxInputWithBtnComponent } from './components/inputs/textbox-input-with-btn/textbox-input-with-btn.component';
import { TimeInputComponent } from './components/inputs/time-input/time-input.component';
import {
  DatepickerHostDirective,
  FormColorStatusDirective,
} from './directives';
import { CommonModule, TitleCasePipe } from '@angular/common';

@NgModule({
    declarations: [
        TimeInputComponent,
        FormInputsComponent,
        FormInputComponent,
        FormComponent,
        TextboxInputComponent,
        TextboxInputWithBtnComponent,
        TagInputComponent,
        SelectInputComponent,
        NopInputComponent,
        TextareaInputComponent,
        DateInputComponent,
        CheckboxInputComponent,
        FileInputComponent,
        FilterButtonsInputComponent,
        RadiosInputComponent,
        AutocompleteInputComponent,
        ValidationInputMessagesComponent,
        InputLabelComponent,
        DatepickerHostDirective,
        SliderInputComponent,
        DateInputTimeComponent,
        FormColorStatusDirective,
        ToggleInputComponent,
        TextboxInputDataListComponent,
        SelectVirtualInputComponent,
    ],
    exports: [
        ReactiveFormsModule,
        FormComponent,
        FormInputComponent,
        TextboxInputComponent,
        TextboxInputWithBtnComponent,
        SelectInputComponent,
        DateInputComponent,
        CheckboxInputComponent,
        RadiosInputComponent,
        FormInputsComponent,
        InputLabelComponent,
        TextareaInputComponent,
        SliderInputComponent,
        ToggleInputComponent,
        ValidationInputMessagesComponent,
        TuiCheckboxLabeledModule,
        ThemeModule,
        CastInputsPipesModule,
        TextboxInputDataListComponent,
        SelectVirtualInputComponent
    ],
    imports: [
        ThemeModule,
        TuiInputModule,
        TuiLabelModule,
        TuiTooltipModule,
        TuiSvgModule,
        TuiTextfieldControllerModule,
        TuiSelectModule,
        TuiDataListWrapperModule,
        TuiSelectOptionModule,
        TuiDataListModule,
        TuiInputDateModule,
        TuiLetModule,
        TuiLoaderModule,
        TuiInputFilesModule,
        TuiInputTimeModule,
        TuiCheckboxLabeledModule,
        TuiInputDateTimeModule,
        TuiRadioListModule,
        TuiGroupModule,
        TuiFilterModule,
        TuiRadioBlockModule,
        TuiRadioLabeledModule,
        TuiRadioModule,
        TuiTextareaModule,
        TuiMultiSelectModule,
        TuiInputTagModule,
        ReactiveFormsModule,
        TuiComboBoxModule,
        TuiFilterByInputPipeModule,
        TuiSliderModule,
        TuiInputNumberModule,
        TuiToggleModule,
        CastInputsPipesModule,
        TranslateModule.forChild(),
        ScrollingModule,
    ],
    providers: [TitleCasePipe]
})
export class FormModule { }
