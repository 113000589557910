import { Injectable, TemplateRef } from '@angular/core';
import { AlertModalComponent } from '@common/components/alert-modal/alert-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export type AlertParams = { title: string; message: string };
@Injectable({ providedIn: 'root' })
export class AlertService {
  toasts: any;

  constructor(private modal: NgbModal) {}

  show(textOrTempl: string | TemplateRef<any>, options: {}) {
    this.toasts.push({ textOrTempl, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  confirm(opts: AlertParams) {
    return this.openModal({ ...opts, type: 'confirm' });
  }

  info(opts: AlertParams) {
    return this.openModal({ ...opts, type: 'info' });
  }

  private openModal({ title, message, type }: AlertParams & { type: 'confirm' | 'info' }) {
    const conf: NgbModalOptions = { centered: true };
    const ref = this.modal.open(AlertModalComponent, conf);
    const component: AlertModalComponent = ref.componentInstance;
    const result: Promise<boolean> = ref.result;

    component.title = title;
    component.message = message;
    component.type = type;

    return result;
  }
}
