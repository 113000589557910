import { Component, TemplateRef } from '@angular/core';
import { AlertService } from '@common/services/alert.service';

@Component({
  selector: 'app-toasts',
  template: `
    <ngb-toast
      *ngFor="let toast of toasts"
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      (hidden)="remove(toast)">
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    </ngb-toast>
  `,
  host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' },
})
export class ToastsContainer {
  constructor(private alert: AlertService) {}

  get toasts() {
    return this.alert.toasts;
  }

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  remove(toast) {
    this.alert.remove(toast);
  }
}
