<!-- Nav tabs -->
<div class="container container-notifications px-4 pt-3">
  <div class="row">
    <div class="col-12">
      <ul class="nav nav-pills nav-fill mb-2" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link pt-1 active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            {{ "PRIVATE.COMPANY.NOTIFICATIONS.NEW" | translate }}
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link pt-1"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >
            {{ "PRIVATE.COMPANY.NOTIFICATIONS.READ" | translate }}
          </a>
        </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div
          class="tab-pane active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <ng-container *ngFor="let not of newNotifications">
            <div
              class="border-bottom p-2 cursor-pointer"
              (click)="updateStatus(not.uuid)"
              [routerLink]="getPath(not.iconType)"
            >
              <app-mini-notification
                class="row"
                [notification]="not"
              ></app-mini-notification></div
          ></ng-container>
          <div class="row p-2 mt-2 justify-content-center fs-6">
            <button (click)="goToNotifications()" class="btn btn-green-outline">
              {{ "PRIVATE.COMPANY.NOTIFICATIONS.BUTTON" | translate }}
            </button>
          </div>
        </div>
        <div
          class="tab-pane"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <ng-container *ngFor="let not of oldNotifications">
            <div
              class="border-bottom p-2 cursor-pointer"
              [routerLink]="getPath(not.iconType)"
            >
              <app-mini-notification
                class="row"
                [notification]="not"
              ></app-mini-notification></div
          ></ng-container>
          <div class="row p-2 mt-2 justify-content-center fs-6">
            <button (click)="goToNotifications()" class="btn btn-green-outline">
              {{ "PRIVATE.COMPANY.NOTIFICATIONS.BUTTON" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
