<header class="duotone">
  <nav class="navbar navbar-expand-lg pt-4">
    <div class="container">
      <a class="navbar-brand me-5" [routerLink]="homeUrl">
        <img src="assets/images/in-place.svg" alt="InPlace" width="160" height="50" />
      </a>
      <button
        class="navbar-toggler border-0 p-2 bg-primary"
        type="button"
        aria-label="Toggle navigation Menu"
        (click)="openMenu.emit()">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarInPlace">
        <ul class="navbar-nav me-auto mb-2 mt-4 mt-lg-0 mb-lg-0 font-anton">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/', 'azienda']" routerLinkActive="active">Aziende</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/', 'candidato']" routerLinkActive="active">Candidati</a>
          </li>

          <ng-content select="li.nav-item"></ng-content>

          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/', 'chi-siamo']" routerLinkActive="active">Chi Siamo</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/', 'supporto']" routerLinkActive="active">Supporto</a>
          </li>
        </ul>

        <!-- <ng-content select="section.actions"></ng-content> -->

        <section class="d-flex actions">
          <ng-container *ngIf="!username">
            <a *ngIf="!hideLoginBtn" class="btn btn-primary btn-sm me-3" [routerLink]="['/', 'login']">Accedi</a>
            <a class="btn btn-outline-primary btn-sm" [routerLink]="['/', 'register']">Registrati</a>
          </ng-container>

          <ng-container *ngIf="username">
            <img class="avatar me-2" src="assets/images/avatar.svg" />
            <span class="wellcome-user text-underline text-primary font-anton">
              <ng-container *ngIf="!impersonator">CIAO {{ username }}</ng-container>
              <ng-container *ngIf="impersonator">Stai impersonando {{ username }}</ng-container>
            </span>
          </ng-container>
        </section>
      </div>
    </div>
  </nav>
</header>
