import { KeyValue } from '@angular/common';
import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseInput } from '../../models';

@Pipe({ name: 'castBaseInputArray' })
export class CastBaseInputArrayPipe implements PipeTransform {
  transform<S>(value: S): BaseInput[] {
      return value as unknown as BaseInput[];
  }
}

@Component({
  selector: 'app-form-inputs',
  templateUrl: './form-inputs.component.html'
})
export class FormInputsComponent {
  @Input() filter: boolean;
  @Input() form: UntypedFormGroup;
  @Input() controls: BaseInput[];

  keyvaluePipeCompareFn(
    a: KeyValue<string, BaseInput>,
    b: KeyValue<string, BaseInput>
  ): number {
    if (a.value.index > b.value.index) {
      return 1;
    }

    if (a.value.index < b.value.index) {
      return -1;
    }
    return 0;
  }
}
