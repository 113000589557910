import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TuiDialogService } from '@taiga-ui/core';
import { AuthService } from 'src/app/core/auth/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-chatbot-btn',
  templateUrl: './chatbot-btn.component.html',
  styleUrls: ['./chatbot-btn.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatbotBtnComponent implements OnInit {
  @ViewChild('templateModal') templateModal: TemplateRef<any>;
  @ViewChild('header') header: TemplateRef<any>;

  isAuthenticated = false;

  constructor(
    private dialogService: TuiDialogService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService
      .isAuthenticated()
      .pipe(untilDestroyed(this))
      .subscribe((auth) => (this.isAuthenticated = auth));
  }

  open() {
    this.dialogService
      .open(this.templateModal, {
        dismissible: true,
        size: 'l',
        label: '',
        header: this.header,
      })
      .subscribe();
  }
}
