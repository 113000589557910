import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
})
export class FooterComponent {
  facebookUrl = environment.social.facebook;
  instagramUrl = environment.social.instagram;
  linkedinUrl = environment.social.linkedin;

  termsUrl = environment.privacy.term;
  privacyUrl = environment.privacy.policy;
  cookieUrl = environment.privacy.cookie;
}
