import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@common/shared.module';

@Component({
  selector: 'app-not-found',
  template: `
    <app-header homeUrl="/"></app-header>

    <main class="py-4 not-found">
      <div class="container text-center">
        <div class="row">
          <div class="col-12">
            <h1>Error 404 not found</h1>
            <p class="lead">Nessuna pagina disponibile</p>
          </div>
          <div class="col-12">
            <h3>Per tornare su inplace...</h3>
            <a class="btn btn-link px-2" [routerLink]="['/']">Torna alla pagina iniziale</a>
          </div>
        </div>
      </div>
    </main>

    <app-footer></app-footer>
  `,
  styles: [
    `
      .not-found {
        height: calc(100vh - 409px);
      }
    `,
  ],
  standalone: true,
  imports: [RouterModule, CommonSharedModule],
})
export class NotFoundComponent {}
