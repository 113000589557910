import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
})
export class HeaderComponent {
  @Input() homeUrl = '/';
  @Input() username: string = null;
  @Input() hideLoginBtn = false;
  @Input() impersonator: string = null;

  @Output() openMenu = new EventEmitter<void>();
}
