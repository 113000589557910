import { Routes } from '@angular/router';
import { AuthenticationGuard } from '@core/auth/guards/authentication-guard.service';
import { NotFoundComponent } from './not-found/not-found.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'azienda',
    loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule),
  },
  {
    path: 'candidato',
    loadChildren: () => import('./workers/workers.module').then(m => m.WorkersModule),
  },
  {
    path: 'chi-siamo',
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
  },
  {
    path: 'supporto',
    loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'private',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'candidato',
        loadChildren: () => import('./worker-page/worker-page.module').then(m => m.WorkerPageModule),
      },
      {
        path: 'azienda',
        loadChildren: () => import('./company-page/company-page.module').then(m => m.CompanyPageModule),
      },
      {
        path: 'delegato',
        loadChildren: () => import('./delegate/delegate.module').then(m => m.DelegateModule),
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
