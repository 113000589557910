import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationState } from 'src/app/shared/notifications/enum/notification-state.enum';
import { NotificationsService } from 'src/app/shared/notifications/services/notifications.service';
import { Notification } from 'src/app/shared/notifications/interfaces/notification';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/auth/services/user.service';
import { RoleType, UserType, role2route } from 'src/app/core/auth/interfaces/role.model';
import { ICON_TYPE } from 'src/app/shared/notifications/enum/icon-type';
import { PaggingRequestParams } from 'src/app/core/interfaces/pagging-request-params';

@UntilDestroy()
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  newNotifications: Notification[];
  oldNotifications: Notification[];
  iconTypeMap = ICON_TYPE;
  constructor(
    private service: NotificationsService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.newNotifications = this.service.newNotifications;
    this.oldNotifications = this.service.oldNotifications;
  }

  goToNotifications = () => {
    this.router.navigate([
      'private',
      role2route(this.userService.userRoleInfo.role),
      'notifications',
    ]);
  };
  getPath(iconType: string): string {
    if (iconType in this.iconTypeMap) {
      return `/private/${this.userService.userRoleInfo.role === RoleType.COMPANY ||
        this.userService.userRoleInfo.role === RoleType.DELEGATE
        ? UserType.COMPANY
        : UserType.WORKER
        }${this.iconTypeMap[iconType].path}`;
    }
    return `/private/${this.userService.userRoleInfo.role === RoleType.COMPANY ||
      this.userService.userRoleInfo.role === RoleType.DELEGATE
      ? UserType.COMPANY
      : UserType.WORKER
      }/notifications`;
  }

  updateStatus(uuid: string) {
    this.service
      .updateNotificationStatus(uuid)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const params: PaggingRequestParams = {
          page: 0,
          size: 2,
        };
        this.service.getNotificationsNumber().subscribe();
        this.service
          .getNotifications(params, NotificationState.NEW)
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            this.service.newNotifications = res.content;
            this.newNotifications = res.content;
          });
        this.service
          .getNotifications(params, NotificationState.OLD)
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            this.service.oldNotifications = res.content;
            this.oldNotifications = res.content;
          });
      });
  }
}
