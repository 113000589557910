import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { TuiDialogService } from '@taiga-ui/core';
import { CheckboxInput } from '../../../models/inputs/checkbox-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-checkbox-input',
  template: `
    <div class="d-flex align-items-center">
      <tui-checkbox-labeled
        [formControl]="control"
        [ngClass]="{ 'fw-bold': control?.boldText }"
        >{{ control.label | translate }}
        <ng-container *ngIf="control?.required"> * </ng-container>
      </tui-checkbox-labeled>

      <ng-template #noControl>
        <input
          id="{{ 'input' + name }}"
          type="checkbox"
          [checked]="checked"
          [indeterminate]="indeterminate"
          (change)="change.emit($event)"
        />
      </ng-template>

      <span *ngIf="control?.infoDialogActive">
        <tui-svg
          (click)="openInfoDialog()"
          size="s"
          style="font: var(--tui-font-text-s); margin-left: 2px;"
          class="cursor-pointer"
          src="tuiIconInfoLarge"
        ></tui-svg>
      </span>
    </div>
  `,
  styles: [
    'app-checkbox-input .fw-bold .content { font-weight: bold !important}',
  ],
})
export class CheckboxInputComponent extends BaseInputComponent {
  @Input() name: string;
  @Input() control?: CheckboxInput;
  @Input() checked: boolean;
  @Input() indeterminate: boolean;
  @Input() label = '';

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor(private dialogService: TuiDialogService) {
    super();
  }

  openInfoDialog() {
    if (this.control?.infoDialogTemplate) {
      this.dialogService
        .open(this.control.infoDialogTemplate, { size: 's', dismissible: true })
        .subscribe();
    }
  }
}
