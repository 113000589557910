import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbActiveOffcanvas,
  NgbDatepickerModule,
  NgbProgressbarModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { AsideMenuComponent } from './components/aside-menu/aside-menu.component';
import { AvailabilityRecapComponent } from './components/availability-recap/availability-recap.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { TimePlannerComponent } from './components/time-planner/time-planner.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgbProgressbarModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
  ],
  exports: [
    CommonModule,
    FooterComponent,
    HeaderComponent,
    AsideMenuComponent,
    DatePickerComponent,
    DateRangeComponent,
    ContactFormComponent,
    TimePlannerComponent,
    AvailabilityRecapComponent,
  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
    AsideMenuComponent,
    DatePickerComponent,
    DateRangeComponent,
    ContactFormComponent,
    TimePlannerComponent,
    AvailabilityRecapComponent,
  ],
  providers: [NgbActiveOffcanvas],
})
export class CommonSharedModule {}
