import { Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { UtilityService } from 'src/app/core/utility.service';
import { SelectInput, TextInput, ValidationStatus } from 'src/app/shared/form';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserService } from 'src/app/core/auth/services/user.service';

import { JobLocationPreference } from 'src/app/core/auth/interfaces/worker.model';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SliderInput } from '../../form/models/inputs/slider-input';

@UntilDestroy()
@Component({
  selector: 'app-job-location-preference-form',
  templateUrl: './job-location-preference-form.component.html',
})
export class JobLocationPrefenceComponent implements OnInit {
  @Input() jobLocationPreferences: JobLocationPreference[]
  placesForm: UntypedFormGroup;  
  placesFormItems: UntypedFormArray;

  constructor(
    private utilityService: UtilityService,
    private formBuilder: UntypedFormBuilder,
    public userService: UserService,
  ) {}

  ngOnInit(): void {
    this._initPlacesForm();
  }

  private _initPlacesForm(): void {
    this.placesForm = this.formBuilder.group({
      items: this.formBuilder.array([])
    });
    if(!!this.jobLocationPreferences){
      this.jobLocationPreferences.forEach((place: JobLocationPreference) => this.addPlace(place))
    } else {
      this.addPlace();
    }
  }

  private createPlace(place: JobLocationPreference): any {
    return this.formBuilder.group({
        region: new SelectInput({
            label: 'PRIVATE.COMPANY.MY_ANNOUNCEMENTS.ANNOUNCEMENT_CREATE.JOB_PLACE.REGION',
            options: of(this.utilityService.getRegions()),
            required: true,
            validationStatus: [ValidationStatus.ERROR.REQUIRED],
            bindValue: true,
            readonly: false,            
            size: '12|12|4|4|4',
            placeholder: 'PRIVATE.COMPANY.MY_ANNOUNCEMENTS.ANNOUNCEMENT_CREATE.JOB_PLACE.REGION',
            value: !!place ? place.region : undefined
          }),
          district: new SelectInput({
            label: 'PRIVATE.COMPANY.MY_ANNOUNCEMENTS.ANNOUNCEMENT_CREATE.JOB_PLACE.PROVINCE',
            options: !!place && !!place.region ? of(this.utilityService.getProvinces( place.region)) : of([]),
            bindValue: true,
            required: true,
            readonly: !!place && !!place.district ? false : true,
            validationStatus: [ValidationStatus.ERROR.REQUIRED],            
            size: '12|12|4|4|4',
            placeholder: 'PRIVATE.COMPANY.MY_ANNOUNCEMENTS.ANNOUNCEMENT_CREATE.JOB_PLACE.PROVINCE',
            value: !!place ? place.district : undefined
          }),
          municipality: new SelectInput({
            label: 'PRIVATE.COMPANY.MY_ANNOUNCEMENTS.ANNOUNCEMENT_CREATE.JOB_PLACE.CITY',
            options: !!place && !!place.district ? of(this.utilityService.getCities(place.district)) : of([]),
            bindValue: true,
            required: true,
            readonly: !!place && !!place.municipality ? false : true,
            validationStatus: [ValidationStatus.ERROR.REQUIRED],
            size: '12|12|4|4|4',
            placeholder: 'PRIVATE.COMPANY.MY_ANNOUNCEMENTS.ANNOUNCEMENT_CREATE.JOB_PLACE.CITY',
            value: !!place ? place.municipality : undefined
          }),
          distance: new SliderInput({
            label: 'PRIVATE.COMPANY.MY_ANNOUNCEMENTS.ANNOUNCEMENT_CREATE.JOB_PLACE.DISTANCE',
            required: true,
            min: 1,
            max: 100,
            validationStatus: [ValidationStatus.ERROR.REQUIRED],
            value: !!place ? place.distance : 10,
            showCurrentValue: true,
            currentValueLabelEnd: 'UTILS.MISURA_DISTANZA',
          }),
      });
  }

  addPlace(place: JobLocationPreference = null): any{
    this.placesFormItems = this.placesForm.get('items') as UntypedFormArray;
    let placeControls = this.createPlace(place);
    placeControls.get("region").valueChanges.subscribe(region => {
      if (region) {
        (placeControls.get('district') as SelectInput)
          .setOptions(of(this.utilityService.getProvinces(region)));
        (placeControls.get('district') as SelectInput).readonly = false;
      } else {
        (placeControls.get('district') as SelectInput).readonly = true;
      }
    });
    placeControls.get("district").valueChanges.subscribe(province => {
      if (province) {
        (placeControls.get('municipality') as SelectInput)
          .setOptions(of(this.utilityService.getCities(province)));
        (placeControls.get('municipality') as SelectInput).readonly = false;
      } else {
        (placeControls.get('municipality') as SelectInput).readonly = true;
      }
    });
    this.placesFormItems.push(placeControls);
    this.placesForm.updateValueAndValidity(); 
  }

  removePlace(index: number){
    this.placesFormItems = this.placesForm.get('items') as UntypedFormArray;
    this.placesFormItems.removeAt(index)
    this.placesForm.updateValueAndValidity(); 
  }
}
