import { ClientTypeEnum } from "src/app/pages/auth/enum/client-type.enum";
import { BankType } from "src/app/pages/private/pages/company/pages/user/enum/bank-type";
import { SignatureState } from "src/app/pages/private/pages/worker/pages/messages/interfaces/final-confirm-info";
import { DocumentType } from "src/app/pages/private/pages/worker/pages/user/models/documents-type";
import { DocumentStatus } from "src/app/pages/private/pages/worker/pages/user/pages/user-worker-profile/models/documents-status";
import { StatoEnum } from "../enum/stato.enum";
import { RoleType } from "./role.model";
import { JobLocationPreference } from "./worker.model";

export type LocalDate = string;
export type LocalDateTime = string;
export type LocalTime = string;
export type Instant = string;
export type Enumerated = string;
export type Year = string;

export enum SedeType {
  FATTURAZIONE = "FATTURAZIONE",
  LAVORO = "LAVORO",
  OPERATIVA = "OPERATIVA",
  LEGALE = "LEGALE",
}
export interface Sede {
  id: number;
  uuid: string;
  nome: string;
  indirizzo: string;
  stato: Enumerated;
  regione: Enumerated;
  provincia: Enumerated;
  comune: Enumerated;
  cap?: string; // not present in 'SedeRequest' BE, but present in 'SedeResponse'
  comuneEstero: string;
  provinciaEstera: string;
  sedeType: SedeType;
}
export interface LegalAddress {
  uuid: string;
  indirizzo: string;
  stato: Enumerated;
  regione: Enumerated;
  provincia: Enumerated;
  comune: Enumerated;
  comuneEstero: string;
  provinciaEstera: string;
  sedeType: string;
}

export interface LegalRepresentative {
  nome: string;
  cognome: string;
  codiceFiscale: string;
  dataDiNascita: LocalDate;
  genere: GenderTypeEnum;
  codiceNazioneDiNascita: Enumerated;
  codiceRegioneDiNascita: Enumerated;
  codiceProvinciaDiNascita: Enumerated;
  codiceComuneDiNascita: Enumerated;
  descComuneEsteroDiNascita: string;
}

export interface CCNLResponse {
  cod: string;
  des: string;
  hhWeek: number;
  minHHWeek: number;
  maxHHWeek: number;
  minHHDay: number;
  maxHHDay: number;
  jobLocationMandatory: boolean;
}

export interface DocumentRegistryModel {
  uuid: string;
  tipoDocumentoCod: string;
  tipoDocumentoDesc: string;
  dataScadenza: LocalDate;
  numero: string;
  enteEmissione: string;
  dataEmissione: LocalDate;
  statoEmissione: Enumerated;
  regioneEmissione: Enumerated;
  provinciaEmissione: Enumerated;
  comuneEmissione: Enumerated;
  comuneEsteroEmissione: string;
  motivoRilascio: string;
  statoRilascio: StatoRilascioEnum;
  tipologiaAlloggio: AlloggioType;
  documentFile: DocumentBinaryResponse;
}


export interface Company extends CompanyAdditionalInfo, CompanyConfig {
  clientType: ClientTypeEnum;
  codiceAttivita: string;
  codiceCcnlPrincipale: CCNLResponse;
  codiceCcnlList: CCNLResponse[];
  codiceComuneDiNascita: Enumerated;
  codiceFiscale: string;
  codiceNazioneDiNascita: Enumerated;
  codiceRegioneDiNascita: Enumerated;
  codiceProvinciaDiNascita: Enumerated;
  codiceUnivocoFatturazione: string;
  cognome: string;
  dataDiNascita: LocalDate;
  descComuneEsteroDiNascita: string;
  documentRegistry: DocumentRegistryModel;
  email: string;
  genere: GenderTypeEnum;
  iban: string;
  legaleRappresentante: LegalRepresentative;
  nome: string;
  pec: string;
  pIva: string;
  posizioneInailList: string[];
  ragioneSociale: string;
  sedeLegale: LegalAddress;
  sedi: Sede[]
  settore: Enumerated;
  created: Instant;
  telefono: string;
  userRole: RoleType;
  userStatus: StatoEnum;
  riskRegistryCompleted: boolean;
  termsFlag: boolean;
  privacyFlag: boolean;
  mediaFlag: boolean;
  promoFlag: boolean;
  profilationFlag: boolean;
  bankType: BankType;
  status: any;
  statusEvents: CompanyStatusEvent[];
  searchCandidatePreferences: CompanyResearchPreferencesData
  foundInCervedAtRegistration: boolean;
  commercialOffersNotificationFrequency: string;
  conversationsNotificationFrequency: string;
  inPersonInterview: boolean;
}

export interface CompanyBillingInfo {
  pec: string;
  codiceUnivocoFatturazione: string;
  iban: string;
  bankType: BankType;
}
export interface CompanyWorkLocations {
  sedi: Sede[];
}

export interface CompanyResearchPreferencesData {
  sector: string;
  task: {cod: string, des: string};
  yearsOfExperience: number;
  jobLocationPreferences: JobLocationPreference[];
}

export interface CompanyResearchPreferences {
  sector: string;
  taskCode: string;
  yearsOfExperience: number;
  jobLocationPreferences: JobLocationPreference[];
}

// TODO
export interface CompanyDelegateInfo {
  nome?: string;
}

export interface CompanyAdditionalInfo {
  candidateCount: number;
  commercialOfferCount: number;
  completitionPercentage: number;
  contractCount: number;
  digitalSignature: SignatureState;
  documentCertified: boolean;
  jobOfferCount: number;
  favouriteCandidatesCount: number;
  riskRegistryCompleted: boolean; //Anagrafica rischi
  riskBoardAtLeastOne: boolean; //Scheda rischi
  billingInformation: boolean; //dati fatturazione
}

export interface CompanyConfig {
  maxNumberCcnl: number;
  maxNumberPositionInail: number;
}

//TODO status copied from worker, to update
export enum CompanyStatus {
  REGISTERED = 'REGISTERED',
  ELIGIBLE = 'ELIGIBLE',
  CONTRACTUALIZABLE = 'CONTRACTUALIZABLE',
  DELETED = 'DELETED'
}

export enum CompanyStatusEvent {
  SET_PROFILE_DATA = 'SET_PROFILE_DATA',
  SET_MAIN_DATA = 'SET_MAIN_DATA',
  UPDATE_MAIN_DATA = 'UPDATE_MAIN_DATA',
  SET_DOCUMENT = 'SET_DOCUMENT',
  CREATE_COMM_OFFER = 'CREATE_COMM_OFFER',
  SET_ACCESSORY_DATA = 'SET_ACCESSORY_DATA',
  REQ_AVAILABILITY = 'REQ_AVAILABILITY',
  REQ_SIGNATURE = 'REQ_SIGNATURE',
  FAIL_SIGNATURE = 'FAIL_SIGNATURE',
  SET_SIGNATURE = 'SET_SIGNATURE'
}


export interface CompanyBaseRequest {
  clientType: ClientTypeEnum;
  promoFlag: boolean;
  ragioneSociale?: string;
  pIva?: string;
  nome?: string;
  cognome?: string;
  codiceFiscale?: string;
  telefono?: string;
  dataDiNascita?: LocalDate;
  statoDiNascita?: Enumerated;
  regioneDiNascita?: Enumerated;
  provinciaDiNascita?: string;
  comuneDiNascita?: string;
  comuneDiNascitaEstero?: string;
  genere?: GenderTypeEnum;
  isRappresentanteLegale?: boolean;
  profilationFlag?: boolean;
}

export interface RegisterCompanyRequest extends CompanyBaseRequest {
  email: string;
  password: string;
  termsFlag: boolean;
  privacyFlag: boolean;
}

export interface CompleteRegisterCompanyRequest  {
  nome?: string;
  cognome?: string;
  codiceFiscale: string;
  ragioneSociale?: string;
  sector: string;
  taskCode: string;
  yearsOfExperience: number;
  preferenceRequest:{
    jobLocationPreferences: JobLocationPreference[];
  }
}

export interface CompleteCompanyProfileRequest {
  nome?: string;
  cognome?: string;
  pIva?: string;
  ragioneSociale?: string;
  telefono?: string;
  codiceFiscale?: string;
  dataDiNascita?: string;
  codiceNazioneDiNascita?: string;
  codiceRegioneDiNascita?: string;
  codiceProvinciaDiNascita?: string;
  codiceComuneDiNascita?: string;
  descComuneEsteroDiNascita?:string;
  genere?: string;

  posizioneInailList: string[];
  codiceAttivita: string;
  codiceSettore: Enumerated;
  codiceCcnlPrincipale: string;
  codiceCcnlList: string[];

  legaleRappresentante: LegalRepresentative;

  sedeLegale: Sede;

  promoFlag: boolean;
  profilationFlag: boolean;
}
export interface ClientUpdateProfileRequest extends CompleteCompanyProfileRequest {
  telefono: string;
}

export interface RegisterCompanyResponse {
  userUuid: string;
}

export interface DocumentBinaryResponse {
  uuid: string;
  tipologia: DocumentType;
  dataCaricamento: LocalDate;
  idDocumentale: string;
  json: string;
  processingStatus: DocumentStatus;
  userUuid: string;
  fileType: DocumentMimeType;
}

export enum GenderTypeEnum {
  MASCHIO = "MASCHIO",
  FEMMINA = "FEMMINA",
}
export enum StatoRilascioEnum {
  ATTESA = "ATTESA",
  RILASCIATO = "RILASCIATO",
}
export enum AlloggioType {
  LOCAZIONE = "LOCAZIONE",
  GRATUITO = "GRATUITO",
}
export enum UploadType {
  FORM = "FORM",
  UPLOAD = "UPLOAD",
}
export enum DocumentMimeType {
  PDF = "PDF",
  BMP = "BMP",
  GIF = "GIF",
  JPEG = "JPEG",
  JPG = "JPG",
  PNG = "PNG",
  DOC = "DOC",
  DOCX = "DOCX",
  MP4 = "MP4",
  QUICKTIME = "QUICKTIME",
  MVK = "MVK",
  MKV = "MKV",
  TXT = "TXT",
}

export enum FullTimeWorkEnum {
  FULL_TIME = 1,
  PART_TIME = 0,
}

export enum NotificationFrequency {
  PUSH = 'Si',
  OFF = 'No',
  DAILY = 'Recap giornaliero'
}
