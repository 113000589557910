import { Component, Input } from '@angular/core';
import { TextInput } from '../../../models/inputs/text-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-textbox-input',
  template: `
    <!-- <input
      id="{{ 'input' + name }}"
      class="sgb-input form-control shape-rectangle nb-transition input-full-width"
      [appColorStatus]="control.colorStatus"
      [type]="control.clearable ? 'search' : control.type"
      [placeholder]="control.placeholder | translate"
      [formControl]="control"
    /> -->

    <ng-container *ngIf="!control.numberFormat">
      <tui-input [tuiTextfieldLabelOutside]="true" [formControl]="control">
        <input
          tuiTextfield
          id="{{ 'input' + name }}"
          [readOnly]="control.readonly"
          [type]="control.type == 'number' ? 'text' : control.type"
          [value]="control.placeholder | translate"
        >
      </tui-input>
    </ng-container>

    <ng-container *ngIf="control.numberFormat">
      <tui-input-number
        id="{{ 'input' + name }}"
        [readOnly]="control.readonly"
        [tuiTextfieldLabelOutside]="true"
        [formControl]="control"
        [min]="control?.min ? control.min : null"
        [max]="control?.max"
        [decimal]="control.showDecimal"
        [precision]="control.decimalPrecision"
        [prefix]="control.prefix"
        [postfix]="control.postfix"
      >
        {{ control.placeholder | translate }}
      </tui-input-number>
    </ng-container>
  `,
})
export class TextboxInputComponent extends BaseInputComponent {
  @Input() name: string;
  @Input() control: TextInput;
}
