<div class="card card-body">
  <div class="row">
    <div class="col-12 col-md-6 title" (click)="goToDetail(announcement?.id)">
      {{ announcement?.title }}
      <span *ngIf="status" class="comp">
        {{ announcement?.compatibility }}% compatibile
      </span>
    </div>
    <div class="col-12 col-md-6 update">
      {{ "SEARCH.WORKER.LAST_UPDATE" | translate }}
      {{ announcement?.date | date : "dd/MM/yyyy" }}
    </div>
    <div class="col-12 col-md-6 workplace">
      <div class="label">
        {{ "SEARCH.INFO.WORKPLACE" | translate }}
      </div>
      <div class="info">
        {{ announcement?.city }} - {{ (announcement?.region ? "MAPPINGS.regions." +  announcement.region : null) | translate}},
        {{ (announcement?.country ? "MAPPINGS.countries." + announcement.country : null) | translate}}
      </div>
    </div>
    <div class="col-12 col-md-6 sector" *ngIf="announcement?.sector">
      <div class="label">{{ "SEARCH.INFO.SECTOR" | translate }}</div>
      <div class="info">{{ "MAPPINGS.sectors." + announcement?.sector | translate }}</div>
    </div>
    <div class="col-12 desc">
      <div class="info" [innerHTML]="announcement?.description | br"></div>
    </div>
    <div class="col-12 bottom d-flex flex-wrap gap-2">
      <button type="button" (click)="goToDetail(announcement?.id)" class="btn btn-green">
        <tui-svg src="tuiIconEye"></tui-svg>
        {{ "SEARCH.BUTTONS.SEE_DETAIL" | translate }}
      </button>
      <button type="button" (click)="apply(announcement?.id)" class="btn btn-green">
        <tui-svg src="tuiIconPlayLarge"></tui-svg>
        {{ "SEARCH.BUTTONS.APPLY" | translate }}
      </button>
    </div>
  </div>
</div>
