<ng-container
  [ngTemplateOutlet]="userRole === roleTypeEnum.COMPANY ? company : (userRole === roleTypeEnum.WORKER ? worker : delegate )"
  [ngTemplateOutletContext]="{ user: userService.user$ | async }">
</ng-container>

<ng-template let-user="user" #company>
  <div class="px-4 py-3">
    <div class="container-user border-bottom pb-3">
      <div class="row">
        <div class="col">
          <span class="fw-bold" *ngIf="user.ragioneSociale">
            {{ user.ragioneSociale }}
          </span>
          <span class="fw-bold" *ngIf="!user.ragioneSociale">
            {{ user.nome }} {{ user.cognome }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col fw-light" *ngIf="user.pIva">
          {{ "PRIVATE.COMPANY.USER.PIVA" | translate }} {{ user.pIva }}
        </div>
      </div>
      <div class="row mt-2 mb-1" *ngIf="!isImpersonating()">
        <div class="col">
          <span class="label-completions">
            {{ "PRIVATE.COMPANY.USER.COMPLETION" | translate }}
          </span>
          <span class="fw-bolder m-2">{{ user.completitionPercentage }} %</span>
        </div>
      </div>
      <progress
        size="s"
        tuiProgressBar
        [color]="'var(--tui-primary)'"
        [value]="user.completitionPercentage"
        max="100"></progress>
      <div class="row mt-4 mb-2">
        <div class="col">
          <span class="fw-bold">
            {{ "PRIVATE.COMPANY.USER.MY_PROFILE" | translate }}
          </span>
        </div>
      </div>
      <div class="row" *ngIf="isImpersonating()">
        <div class="col">
          <a
            tuiLink
            class="tui-link-user-panel"
            (click)="deimpersonate()"
            icon="tuiIconMinusCircle"
            iconAlign="left">Smetti di impersonare</a>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <a
            routerLinkActive="active"
            [routerLink]="'utente'"
            tuiLink
            class="tui-link-user-panel"
            icon="tuiIconUser"
            iconAlign="left"
            (click)="close()">{{ "PRIVATE.COMPANY.USER.CLIENT_PROFILE" | translate }}</a>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <a
            routerLinkActive="active"
            [routerLink]="'documenti/offert/create'"
            tuiLink
            class="tui-link-user-panel"
            icon="tuiIconClipboard"
            iconAlign="left"
            (click)="close()">{{ "PRIVATE.COMPANY.USER.REQUEST_OFFER" | translate }}</a>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <a
            routerLinkActive="active"
            [routerLink]="'utente/delegates'"
            tuiLink
            class="tui-link-user-panel"
            icon="tuiIconFile"
            iconAlign="left"
            (click)="close()">{{ "PRIVATE.COMPANY.USER.DELEGATES" | translate }}</a>
        </div>
      </div>
      <!--
      <div class="row mt-2">
        <div class="col">
          <a
            routerLinkActive="active"
            [routerLink]="'utente'"
            tuiLink
            class="tui-link-user-panel"
            icon="tuiIconLock"
            iconAlign="left"
            >{{ "PRIVATE.COMPANY.USER.CHANGE_PWD" | translate }}</a
          >
        </div>
      </div>-->
    </div>
    <div class="container-user border-bottom py-3">
      <div class="row">
        <div class="col">
          <span class="fw-bold">
            {{ "PRIVATE.COMPANY.USER.SUPPORT" | translate }}
          </span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <a
            routerLinkActive="active"
            [routerLink]="'support/faq/azienda'"
            tuiLink
            class="tui-link-user-panel"
            icon="tuiIconUser"
            iconAlign="left"
            (click)="close()">{{ "PRIVATE.COMPANY.USER.CONTACT_US" | translate }}</a>
        </div>
      </div>
    </div>
    <div class="container-user py-3">
      <div class="row">
        <div class="col">
          <a
            (click)="onLogOut()"
            tuiLink
            class="tui-link-user-panel"
            icon="tuiIconMinusCircle"
            iconAlign="left">{{ "PRIVATE.COMPANY.USER.EXIT" | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-user="user" #worker>
  <div class="px-4 py-3">
    <div class="container-user border-bottom pb-3">
      <div class="row">
        <div class="col">
          <span class="fw-bold"> {{ user.nome }} {{ user.cognome }} </span>
        </div>
      </div>
      <div class="row">
        <div class="col fw-light">
          {{ "PRIVATE.WORKER.USER_DROPDOWN.CF" | translate }}
          {{ user.codiceFiscale }}
        </div>
      </div>
      <div class="row mt-2 mb-1">
        <div class="col">
          <span class="label-completions">
            {{ "PRIVATE.WORKER.USER_DROPDOWN.COMPLETION" | translate }}
          </span>
          <span class="fw-bolder m-2">{{ user.completitionPercentage }} %</span>
        </div>
      </div>
      <progress
        size="s"
        tuiProgressBar
        [color]="'var(--tui-primary)'"
        [value]="user.completitionPercentage"
        max="100"></progress>
      <div class="row mt-4 mb-2">
        <div class="col">
          <span class="fw-bold">
            {{ "PRIVATE.WORKER.USER_DROPDOWN.MY_PROFILE" | translate }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <a
            routerLinkActive="active"
            [routerLink]="'utente'"
            tuiLink
            class="tui-link-user-panel"
            icon="tuiIconUser"
            iconAlign="left"
            (click)="close()">{{ "PRIVATE.WORKER.USER_DROPDOWN.WORKER_PROFILE" | translate }}</a>
        </div>
      </div>
      <!--
      <div class="row mt-2">
        <div class="col">
          <a
            routerLinkActive="active"
            [routerLink]="'utente'"
            tuiLink
            class="tui-link-user-panel"
            icon="tuiIconLock"
            iconAlign="left"
            >{{ "PRIVATE.WORKER.USER_DROPDOWN.CHANGE_PWD" | translate }}</a
          >
        </div>
      </div>-->
    </div>
    <div class="container-user border-bottom py-3">
      <div class="row">
        <div class="col">
          <span class="fw-bold">
            {{ "PRIVATE.WORKER.USER_DROPDOWN.SUPPORT" | translate }}
          </span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <a
            routerLinkActive="active"
            [routerLink]="'support'"
            tuiLink
            class="tui-link-user-panel"
            icon="tuiIconUser"
            iconAlign="left"
            (click)="close()">{{ "PRIVATE.WORKER.USER_DROPDOWN.CONTACT_US" | translate }}</a>
        </div>
      </div>
    </div>
    <div class="container-user py-3">
      <div class="row">
        <div class="col">
          <a
            tuiLink
            class="tui-link-user-panel"
            (click)="onLogOut()"
            icon="tuiIconMinusCircle"
            iconAlign="left">{{ "PRIVATE.WORKER.USER_DROPDOWN.EXIT" | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template let-user="user" #delegate>
  <div class="px-4 py-3">
    <div class="container-user border-bottom pb-3">
      <div class="row">
        <div class="col">
          <span class="fw-bold"> {{ user?.username }}</span>
        </div>
      </div>
      <div class="row mt-2 mb-1">
        <div class="col">
          <span class="label-completions">
            {{ "PRIVATE.WORKER.USER_DROPDOWN.COMPLETION" | translate }}
          </span>
          <span class="fw-bolder m-2">100 %</span>
        </div>
      </div>
      <progress size="s" tuiProgressBar [value]="100" max="100" [color]="'var(--tui-primary)'"></progress>
    </div>
    <div class="container-user py-3">
      <div class="row">
        <div class="col">
          <a
            (click)="onLogOut()"
            tuiLink
            class="tui-link-user-panel"
            icon="tuiIconMinusCircle"
            iconAlign="left">{{ "PRIVATE.COMPANY.USER.EXIT" | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
