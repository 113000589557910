import { Injectable } from '@angular/core';
import { ApiClientService as ApiClient} from 'src/app/api-client.service';
import { Observable } from 'rxjs';
import { SelectOption } from 'src/app/shared/form';
import { DocumentType } from '../../user/models/documents-type';
import { Documents } from '../model/documents.model';
import { Union, UpsertUnionResponse } from '../pages/contractual-documents/model/union';
import { BankingData } from '../pages/contractual-documents/model/banking-data';
import { TaxDeduction } from '../pages/contractual-documents/model/tax-deductions';
import { Tfr } from '../pages/contractual-documents/model/tfr';
import { UnemploymentStatus } from '../pages/contractual-documents/model/unemployment-status';

@Injectable()
export class DocumentsService {
  constructor(private apiClient: ApiClient) {}

  getDocuments(): Observable<Documents> {
    return this.apiClient.request('getDocuments');
  }

  getUnions(): Observable<Union[]> {
    return this.apiClient.request('getUnions');
  }

  getUnionsNameList(): Observable<SelectOption[]> {
    return this.apiClient.request('getUnionsNameList');
  }

  getUnionsCodList(unionCod: string): Observable<SelectOption[]> {
    return this.apiClient.request('getUnionsCodList', null, null, { codSindacato: unionCod });
  }

  saveUnions(request: Union[]): Observable<void> {
    return this.apiClient.request<void>('saveUnions', request);
  }

  getSpecificDocument(type: DocumentType): Observable<BankingData|TaxDeduction|Tfr|UnemploymentStatus> {
    // The return from DB is JSON and is returned as string to FE (without deserialize it in a object).
    // Here I added interfaces anyway for ease of data manipulation on FE.
    return this.apiClient.request('getSpecificDocument', null, null, { tipoDocumento: type });
  }

  saveTaxDeductions(params) {
    return this.apiClient.request('saveTaxDeductions', params);
  }

  getFamilyStatus() {
    return this.apiClient.request('getFamilyStatus');
  }

  saveFamilyStatus(params): Observable<void> {
    return this.apiClient.request<void>('saveFamilyStatus', params);
  }

  getSelectValueList(type): Observable<SelectOption[]> {
    return this.apiClient.request('getDocumentsSelectValueList', null, null, {
      fieldType: type,
    });
  }

  getForeignBank(): Observable<SelectOption[]> {
    return this.apiClient.request('getForeignBank');
  }

  saveBankData(params): Observable<void> {
    return this.apiClient.request<void>('saveBankData', params);
  }

  saveUnemploymentStatusData(params): Observable<void> {
    return this.apiClient.request<void>('saveUnemploymentStatusData', params);
  }

  getPensionsFund(): Observable<SelectOption[]> {
    return this.apiClient.request('getPensionsFund');
  }

  saveTfrData(params): Observable<void> {
    return this.apiClient.request<void>('saveTfrData', params);
  }

  getPaysLipsLink() {
    return this.apiClient.request('getPaysLipsLink');
  }

  workerHasSignedContracts(): Observable<boolean> {
    return this.apiClient.request<boolean>('workerHasSignedContracts');
  }

  documentsToSign(): Observable<string> {
    return this.apiClient.request<string>('workerDocumentsToSign');
  }
}
