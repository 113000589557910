import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { environment } from 'src/environments/environment';
import { RegisterService } from 'src/app/pages/auth/services/register.service';

@Component({
  selector: 'app-privacy-dialog',
  templateUrl: './privacy-dialog.component.html',
  styleUrls: ['./privacy-dialog.component.scss'],
})
export class PrivacyDialogComponent implements OnInit {
  constructor(
    private registerService: RegisterService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<boolean>
  ) {}

  privacyForm = new UntypedFormGroup({
    termsFlag: new UntypedFormControl(false, Validators.requiredTrue),
    mediaFlag: new UntypedFormControl(false, Validators.requiredTrue),
    privacyFlag: new UntypedFormControl(false, Validators.requiredTrue),
    promoFlag: new UntypedFormControl(false),
  });

  ngOnInit(): void {}

  privacy() {
    window.open(`${environment.privacy.candidato}`, '_blank');
  }
  viewTerms() {
    window.open(`${environment.privacy.term}`, '_blank');
  }

  viewInfo() {
    window.open(`${environment.privacy.candidato}`, '_blank');
  }

  viewMedia() {
    window.open(`${environment.privacy.video_img}`, '_blank');
  }

  cancel() {
    this.context.completeWith(false);
  }

  confirm() {
    this.registerService.setPrivacyFormValues(this.privacyForm);
    this.context.completeWith(true);
  }
}
