import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {NgxSpinnerService} from 'ngx-spinner';
import {DateInput, Form, SelectInput} from 'src/app/shared/form';
import {of} from 'rxjs';
import {UtilityService} from 'src/app/core/utility.service';
import {ServerPageableDataSource} from 'src/app/shared/table/models/server-pageable-data-source';
import {UserType} from 'src/app/core/interfaces/user-type.enum';
import {AvailabilityService} from 'src/app/shared/services/availability.service';
import {Company} from 'src/app/core/auth/interfaces/company.model';
import {UserService} from 'src/app/core/auth/services/user.service';
import {InterviewListItem, InterviewStatus} from 'src/app/shared/interfaces/interview.model';
import {
  DocumentsService as CompanyDocumentsService
} from '../../../../pages/private/pages/company/pages/documents/services/documents.service';
import {
  DocumentsService as WorkerDocumentsService
} from '../../../../pages/private/pages/worker/pages/documents/services/documents.service';
import {environment} from '../../../../../environments/environment';


@UntilDestroy()
@Component({
  selector: 'app-availability-list',
  templateUrl: './availability-list.component.html',
  styleUrls: ['./availability-list.component.scss'],
})
export class AvailabilityListComponent implements OnInit  {
  @Input() userType: UserType;
  dataSource: ServerPageableDataSource<InterviewListItem[]>
  user: Company = this.userService.getUser() as Company;
  isFiltersAccordionOpen: boolean = false;
  filterForm: Form;

  pageSize = 10;
  renewalSuccess = false;
  extensionSuccess = false;
  signatureLink: string =  null;

  get InterviewStatus() {
    return InterviewStatus;
  }

  constructor(
    private i18n: TranslateService,
    private loading: NgxSpinnerService,
    private utilityService: UtilityService,
    private availabilityService: AvailabilityService,
    private userService: UserService,
    private companyDocumentService: CompanyDocumentsService,
    private workerDocumentService: WorkerDocumentsService
  ) {
    //this.userType = UserType.COMPANY;
   }

  ngOnInit(): void {
    let dataService = this.availabilityService.getAvailabilityListCompany
    if(this.userType == UserType.WORKER)
      dataService = this.availabilityService.getAvailabilityListWorker

    this.loading.show();
    this.dataSource= new ServerPageableDataSource({

      observable: dataService.bind(this.availabilityService, {
        page: 0,
        size: this.pageSize,
        type: null,
        workerId: null,
        clientId: null,
        status: null,
        start: null,
        end: null,
        createdAt: null,
      }),
    });
    this.dataSource.setCatchErrorFunction(() => this.loading.hide());
    this.dataSource.paging(0, this.pageSize)
    this.dataSource.rows$.subscribe(() => this.loading.hide());
    /*
    this.dataSource.rows$.pipe(
      map((e: any) => {
        if(this.userType == UserType.WORKER)
          return e.map((f: any)=>f["status"] = f["status"] == "PENDING_PAYMENT" ? "ACCEPTED" : f["status"])
        return e
      })
    ).subscribe(() => this.loading.hide());
    */
    this.filterForm = new Form({
      header: {
        show: false,
      },
      controls: {
        taskCode: new SelectInput({
          label: 'PRIVATE.COMMON.INTERVIEWS.TABLE.TASK',
          placeholder: 'PRIVATE.COMMON.INTERVIEWS.TABLE.TASK',
          options: this.utilityService.getTaskListAll(),
        }),
        from: new DateInput({
          label: 'PRIVATE.COMMON.INTERVIEWS.TABLE.INTERVIEW_DATE_FROM',
          placeholder: 'PRIVATE.COMMON.INTERVIEWS.TABLE.INTERVIEW_DATE_FROM'
        }),
        to: new DateInput({
          label: 'PRIVATE.COMMON.INTERVIEWS.TABLE.INTERVIEW_DATE_TO',
          placeholder: 'PRIVATE.COMMON.INTERVIEWS.TABLE.INTERVIINTERVIEW_DATE_TOEW_DATE',
        }),
        status: new SelectInput({
          label: 'PRIVATE.COMMON.INTERVIEWS.TABLE.STATUS',
          placeholder: 'PRIVATE.COMMON.INTERVIEWS.TABLE.STATUS',
          options: of(this.getInterviewStatuses())
        }),
      }
    });
    this.getContractSignatureButtonStatus();
  }

  getContractSignatureButtonStatus(){
    this.signatureLink = null;
    if (this.userType === UserType.WORKER && environment.contractSignatureFeature) {
      this.workerDocumentService.documentsToSign()
        .subscribe((resp: any) => this.signatureLink = resp?.link, err => this.signatureLink = null);
    } else {
      const isDelegate = !!this.userService.getIdentity().impersonator;
      if (!isDelegate && environment.contractSignatureFeature) {
        this.companyDocumentService.documentsToSign()
          .subscribe((resp: any) => this.signatureLink = resp?.link, err => this.signatureLink = null);
      }
    }
  }

  onGoToSignContracts(){
    if (this.signatureLink){
      window.open(this.signatureLink, '_blank');
    }
  }

  getInterviewStatuses(){
    return (Object.keys(InterviewStatus) as Array<keyof typeof InterviewStatus>)
      .map((key) => ({cod: key, des:this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.STATUS.'+key)}))
  }

  applyFilters(page?: number){
    this.loading.show();
    let dataService = this.availabilityService.getAvailabilityListCompany
    if(this.userType == UserType.WORKER)
      dataService = this.availabilityService.getAvailabilityListWorker

    this.dataSource.setObservable(
      dataService.bind(this.availabilityService, {
        page: !!page ? page : 0,
        size: this.pageSize,
        type: null,
        workerId: null,
        clientId: null,
        status: null,
        start: null,
        end: null,
        createdAt: null,
        /*
        taskCode: this.filterForm.value.taskCode,
        status: this.filterForm.value.status,
        at: !!this.filterForm.value.from || !!this.filterForm.value.to ? {
          from: !!this.filterForm.value.from ?
              this.utilityService.getLocalDateTimeFromTuiDay(this.filterForm.value.from)+"Z" : null,
          to: !!this.filterForm.value.to ?
              this.utilityService.getLocalDateTimeFromTuiDay(this.filterForm.value.to)+"Z" : null,
        } :  null,
        type: null,
        */
      })
    );
    this.dataSource.refresh();
  }
}
