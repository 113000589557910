import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from 'src/app/core/auth/interfaces/role.model';
import { TuiDialogService } from '@taiga-ui/core';
import { GeneralLoginOverlayComponent } from './general-login-overlay/general-login-overlay.component';
import { CandidateLoginOverlayComponent } from './candidate-login-overlay/candidate-login-overlay.component';
import { CompanyLoginOverlayComponent } from './company-login-overlay/company-login-overlay.component';
import { CloseOverlayNextStep } from './overlay-next-step.enum';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  constructor(private router: Router, private dialog: TuiDialogService) {}

  generalLoginOverlayOpen() {
    this.dialog
      .open(new PolymorpheusComponent(GeneralLoginOverlayComponent), {
        size: 'l',
      })
      .subscribe((r:any) => {
        if (r) this.triggerNextStep(r as CloseOverlayNextStep);
      });
  }

  candidateLoginOverlayOpen() {
    this.dialog
      .open(new PolymorpheusComponent(CandidateLoginOverlayComponent), {
        size: 'l',
      })
      .subscribe((r: any) => {
        if (r) this.triggerNextStep(r as CloseOverlayNextStep);
      });
  }

  companyLoginOverlayOpen() {
    this.dialog
      .open(new PolymorpheusComponent(CompanyLoginOverlayComponent), {
        size: 'l',
      })
      .subscribe((r: any) => {
        if (r) this.triggerNextStep(r as CloseOverlayNextStep);
      });
  }

  redirectToCandidateRegistration() {
    const userType = UserType.WORKER;
    this.router.navigate(['auth', 'register', 'worker'], {
      queryParams: { userType },
    });
  }

  redirectToCompanyRegistration() {
    const userType = UserType.COMPANY;
    this.router.navigate(['auth', 'register', 'company'], {
      queryParams: { userType },
    });
  }

  redirectToGeneralRegistration() {
    this.router.navigate(['auth', 'register', 'general']);
  }
  redirectToLogin() {
    this.router.navigate(['auth', 'login']);
  }

  redirectToCandidateProfile() {
    this.router.navigate(['private', 'candidato', 'utente', 'user-profile'], {
      queryParams: { loggedin: 'worker' },
    });
  }

  redirectToCandidateIncompleteRegistration() {
    this.router.navigate(['auth', 'register', 'worker-completion']);
  }

  private triggerNextStep(nextStep: CloseOverlayNextStep) {
    switch (nextStep) {
      case CloseOverlayNextStep.CANDIDATE_LOGIN_OVERLAY_OPEN:
        this.candidateLoginOverlayOpen();
        break;
      case CloseOverlayNextStep.COMPANY_LOGIN_OVERLAY_OPEN:
        this.companyLoginOverlayOpen();
        break;
      case CloseOverlayNextStep.GENERAL_LOGIN_OVERLAY_OPEN:
        this.generalLoginOverlayOpen();
        break;
      case CloseOverlayNextStep.REDIERCT_TO_COMPANY_REGISTRATION:
        this.redirectToCompanyRegistration();
        break;
      case CloseOverlayNextStep.REDIRECT_TO_CANDIDATE_REGISTRATION:
        this.redirectToCandidateRegistration();
        break;
      case CloseOverlayNextStep.REDIRECT_TO_GENERAL_REGISTRATION:
        this.redirectToGeneralRegistration();
        break;
      case CloseOverlayNextStep.REDIRECT_TO_LOGIN:
        this.redirectToLogin();
        break;
      case CloseOverlayNextStep.REDIRECT_TO_CANDIDATE_PROFILE:
        this.redirectToCandidateProfile();
        break;
      case CloseOverlayNextStep.REDIRECT_TO_CANDIDATE_INCOMPLETE_REGISTRATION:
        this.redirectToCandidateIncompleteRegistration();
        break;
      case CloseOverlayNextStep.NONE:
      default:
        return;
    }
  }
}
export { CloseOverlayNextStep };
