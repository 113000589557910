<div class="profile-progress-section my-5">
  <h5>{{ "PRIVATE.WORKER.USER_DETAIL.COMPLETITION_TITLE" | translate }}</h5>
  <div class="profile-progress-card">
    <div class="donut-container" [style]="{
        '--percentage-complete': percentageCompleteInDeg + 'deg',
        '--percentage-todo': percentageTodoInDeg + 'deg'
      }">
      <div class="donut-chart">
        <div class="center-plus"></div>
        <div id="completed" class="portion-block">
          <div class="circle"></div>
        </div>
        <div class="center">
          <div class="percentage">
            <span>{{ percentageComplete }}</span>
            %
          </div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="title">
        <h6 class="text" *ngIf="
            !userSignature ||
            !user.documentCertified ||
            userStatus == StatoEnum.INCOMPLETO
          ">
          {{ "PRIVATE.WORKER.USER_DETAIL.COMPLETITION_SUBTITLE" | translate }}
        </h6>
        <h6 class="text" *ngIf="
            userSignature &&
            user.documentCertified &&
            userStatus != StatoEnum.INCOMPLETO
          ">
          {{
          "PRIVATE.WORKER.USER_DETAIL.COMPLETITION_SUBTITLE_DONE" | translate
          }}
        </h6>
        <div class="button cursor-pointer" (click)="openInfoDialog()">
          <!-- tooltip for some reason doesn't work right... tui doesn't either
            data-toggle="tooltip"
            data-placement="bottom"
            data-trigger="click focus"
            [title]="'PRIVATE.COMPANY.USER.USER_PROFILE.INFO.INFO' | translate" -->
          ?
        </div>
      </div>
      <div class="data">
        <!-- SIGNATURE -->
        <div class="completion-tag" *ngIf="userSignature == SignatureStateEnum.FALSE">
          <tui-svg src="tuiIconCloseLarge" class="icon"></tui-svg>
          {{ "PRIVATE.COMPANY.USER.USER_PROFILE.INFO.SIGNATURE" | translate }}
        </div>
        <div class="completion-tag" *ngIf="userSignature == SignatureStateEnum.SEND">
          <tui-svg src="tuiIconCloseLarge" class="icon"></tui-svg>
          {{ "PRIVATE.COMPANY.USER.USER_PROFILE.INFO.SIGNATURE_SEND" | translate }}
        </div>
        <div class="completion-tag" *ngIf="userSignature == SignatureStateEnum.TRUE">
          <tui-svg src="tuiIconCheck" class="icon"></tui-svg>
          {{
          "PRIVATE.COMPANY.USER.USER_PROFILE.INFO.SIGNATURE_ACTIVE"
          | translate
          }}
        </div>
        <!-- DOC CERTIFIED -->
        <div *ngIf="!user.documentCertified">
          <div class="completion-tag">
            <tui-svg src="tuiIconCloseLarge" class="icon"></tui-svg>
            {{ "PRIVATE.COMPANY.USER.USER_PROFILE.INFO.DOCUMENTS" | translate }}
          </div>
        </div>
        <div *ngIf="user.documentCertified">
          <div class="completion-tag">
            <tui-svg src="tuiIconCheck" class="icon"></tui-svg>
            {{
            "PRIVATE.COMPANY.USER.USER_PROFILE.INFO.DOCUMENTS_VERIFIED"
            | translate
            }}
          </div>
        </div>
        <!-- BUTTONS -->
        <button *ngIf="showGoToSignatureBtn" class="button-signature btn btn-green-outline"
          (click)="onGoToSignature(modalSignature)">
          {{
          "PRIVATE.COMPANY.USER.USER_PROFILE.INFO.BUTTON_SIGNATURE"
          | translate
          }}
        </button>
        <button *ngIf="showCompleteProfileBtn" class="button-signature btn btn-green-outline"
          (click)="onGoToCompleteProfile()">
          {{
          "PRIVATE.COMPANY.USER.USER_PROFILE.INFO.BUTTON_PROFILE" | translate
          }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template let-observer #modalSignature>
  <div class="d-flex justify-content-center pt-3">
    {{
    "PRIVATE.COMPANY.USER.USER_PROFILE.INFO.DIALOG_SIGNATURE.TEXT" | translate
    }}
  </div>
</ng-template>

<ng-template #infoDialogTemplate>
  <div class="mt-2">
    <p>
      {{ "PRIVATE.COMPANY.USER.USER_PROFILE.INFO.INFO" | translate }}
    </p>
  </div>
</ng-template>
