import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TuiDay } from '@taiga-ui/cdk';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClientService as ApiClient } from 'src/app/api-client.service';
import {
  Company,
  CompanyAdditionalInfo,
  CompanyBillingInfo,
  CompanyConfig,
  CompanyResearchPreferences,
  CompanyWorkLocations,
  CompleteCompanyProfileRequest,
  CompleteRegisterCompanyRequest
} from 'src/app/core/auth/interfaces/company.model';
import {
  ClientDelegationResponse,
  CreateDelegateRequest,
  DelegateResponse,
  DelegationRole,
  FindDelegateRequest,
  RemoveClientDelegations,
} from 'src/app/core/auth/interfaces/delegate.model';
import { UserService } from 'src/app/core/auth/services/user.service';
import { EditCompanyDocumentRequest } from '../../documents/model/documents.model';

const dr2label = {
  // todo i18n
  [DelegationRole.ROLE_DELEGATE_PROFILE]: 'Completamento/Modifica Profilo',
  [DelegationRole.ROLE_DELEGATE_RISK_BOARD]: 'Schede rischi',
  [DelegationRole.ROLE_DELEGATE_INVOICES]: 'Fatture',
  [DelegationRole.ROLE_DELEGATE_PROPOSALS_CONTRACTS]: 'Proposte e Contratti',
  [DelegationRole.ROLE_DELEGATE_TIMESHEET]: 'Presenze',
  [DelegationRole.ROLE_DELEGATE_COMMERCIAL_OFFERS]: 'Preventivi',
  [DelegationRole.ROLE_DELEGATE_JOB_OFFERS_INTERVIEWS]: 'Annunci e colloqui',
  [DelegationRole.ROLE_DELEGATE_CONVERSATIONS]: 'Chat',
};

export function delegationRoleLabel(value: DelegationRole) {
  return dr2label[value];
}

@Injectable()
export class UserProfileService {
  constructor(
    private apiClient: ApiClient,
    private httpClient: HttpClient,
    private userService: UserService,
    private loading: NgxSpinnerService
  ) {}

  getCompanyProfile(): Observable<Company> {
    let headers = new HttpHeaders();

    const authAppTokenString = sessionStorage.getItem('auth_app_token');
    const authAppToken = authAppTokenString
      ? JSON.parse(authAppTokenString)
      : authAppTokenString;

    if (authAppToken) {
      headers = headers.set('Authorization', `Bearer ${authAppToken.value}`);
    }
    return this.httpClient.get<Company>(
      this.apiClient.getUrlByApiName('getCompanyData'),
      {
        headers,
      }
    );
  }

  completeCompanyProfile(
    formValues: CompleteCompanyProfileRequest
  ): Observable<any> {
    return this.httpClient.post(
      this.apiClient.getUrlByApiName('completeCompanyProfile'),
      formValues
    );
  }

  completeCompanyRegistration(
    formValues: CompleteRegisterCompanyRequest
  ): Observable<any> {
    let headers = new HttpHeaders();

    const authAppTokenString = sessionStorage.getItem('auth_app_token');
    let tokenObject = JSON.parse(authAppTokenString);
    if (!!tokenObject && !!tokenObject.value) {
      headers = headers.set('Authorization', `Bearer ${tokenObject.value}`);
    }
    return this.httpClient.post(
      this.apiClient.getUrlByApiName('completeCompanyRegistration'),
      formValues,
      { headers }
    );
  }

  async _reallyUpdateProfileInfo(): Promise<Company> {
    try {
      const company = this.apiClient
        .request<Company>('getCompanyData')
        .toPromise();
      const additionalData = this.apiClient
        .request<CompanyAdditionalInfo>('getAdditionalCompanyData')
        .toPromise();
      const companyConfig = this.apiClient
        .request<CompanyConfig>('getClientConfig')
        .toPromise();

      const [c1, c2, c3] = await Promise.all([
        company,
        additionalData,
        companyConfig,
      ]);

      const data = {
        ...c1,
        ...c2,
        ...c3,
      };
      this.userService.setUser(data);
      return data;
    } catch (err) {
      this.loading.hide();
      throw err;
    }
  }

  updateProfileInfo(): Observable<Company> {
    return from(this._reallyUpdateProfileInfo());
  }

  updateBillingInfo(formValues: CompanyBillingInfo): Observable<any> {
    return this.apiClient.request('updateBillingInfo', formValues);
  }

  updateWorkLocations(formValues: CompanyWorkLocations): Observable<any> {
    return this.apiClient.request('updateWorkLocations', formValues);
  }

  updateSearchCandidatePreferences(
    formValues: CompanyResearchPreferences
  ): Observable<any> {
    return this.apiClient.request(
      'updateSearchCandidatePreferences',
      formValues
    );
  }

  addClientDocument(formValues: EditCompanyDocumentRequest): Observable<any> {
    const formData = new FormData();
    for (const name in formValues) {
      if (formValues[name]) {
        const value = formValues[name];
        if (value instanceof TuiDay) {
          formData.append(name, value.toJSON());
        } else {
          formData.append(name, formValues[name]);
        }
      }
    }

    return this.httpClient.put(
      this.apiClient.getUrlByApiName('uploadClientDocument'),
      formData
    );
  }

  downloadDocument(uuid: string): Observable<any> {
    const api = this.apiClient.getUrlByApiName('downloadClientDocument', {
      uuid,
    });

    return this.httpClient
      .get(api, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response) => {
          const contentDisposition = response.headers.get(
            'Content-Disposition'
          );
          const filename = contentDisposition.split(';')[1].split('"')[1];
          const blob = new Blob([response.body], { type: response.body.type });
          saveAs(blob, filename);
        })
      );
  }

  getClientDelegations(): Observable<ClientDelegationResponse[]> {
    return this.apiClient.request('getClientDelegations');
  }
  getDelegate(): Observable<DelegateResponse> {
    return this.apiClient.request('getDelegate');
  }
  findDelegate(req: FindDelegateRequest): Observable<any> {
    return this.apiClient.request('findClientDelegate', req);
  }
  removeClientDelegations(reqs: RemoveClientDelegations[]): Observable<any> {
    return this.apiClient.request('removeClientDelegations', reqs);
  }
  inviteClientDelegate(req: CreateDelegateRequest): Observable<any> {
    return this.apiClient.request('inviteClientDelegate', req);
  }
  deleteAndReplaceDelegationRequest(
    delegateId,
    req: FindDelegateRequest
  ): Observable<any> {
    return this.apiClient.request(
      'deleteAndReplaceDelegationRequest',
      req,
      null,
      {
        delegateId: delegateId,
      }
    );
  }
  deleteAndReplaceDelegationRequestForNotExistingDelegate(
    delegateId,
    req: CreateDelegateRequest
  ): Observable<any> {
    return this.apiClient.request(
      'deleteAndReplaceDelegationRequestForNotExistingDelegate',
      req,
      null,
      {
        delegateId: delegateId,
      }
    );
  }

  addClientSignature(
    biometricIdentificationTerms: boolean = false
  ): Observable<any> {
    const params = new HttpParams().set(
      'biometricIdentificationTerms',
      JSON.stringify(biometricIdentificationTerms)
    );
    return this.httpClient.get(
      this.apiClient.getUrlByApiName('requestSignature'),
      {
        params: params,
        responseType: "text"
      }
    );
  }

  deleteUser(): Observable<any> {
    return this.apiClient.request('deleteClient');
  }

  savePhoto(formValues) {
    let headers = new HttpHeaders();
    const formData = new FormData();
    for (const name in formValues) {
      if (name) {
        const value = formValues[name];
        if (value instanceof TuiDay) {
          formData.append(name, value.toJSON());
        } else {
          formData.append(name, value);
        }
      }
    }
    return this.httpClient.post(
      this.apiClient.getUrlByApiName('saveCompanyPhoto'),
      formData,
      {
        headers,
      }
    );
  }
}
