import { Component, Input } from '@angular/core';
import { TuiDialogService } from '@taiga-ui/core';
import { BaseInput } from '../../models';

@Component({
  selector: 'app-input-label',
  template: `
    <label
      for="{{ 'input' + name }}"
      class="sgb-label"
      style="text-align: left; position: relative"
      *ngIf="
        field.label &&
        field.inputType !== 'checkbox' &&
        field.inputType !== 'fieldset'
      "
      >{{ field.label | translate }}

      <span *ngIf="field.required" class="font-weight-light"> * </span>
      <span *ngIf="field?.tooltipActive">
        <tui-tooltip
          describeId="{{ 'id_' + name }}"
          content="{{ field?.tooltipLabel | translate }}"
          direction="top-right"
        ></tui-tooltip>
      </span>
      <span *ngIf="field?.infoDialogActive">
        <tui-svg
          (click)="openInfoDialog()"
          size="s"
          style="font-size: inherit;"
          class="cursor-pointer"
          src="tuiIconInfoLarge"
        ></tui-svg>
      </span>
    </label>
  `,
})
export class InputLabelComponent {
  @Input() name: string;
  @Input() field: BaseInput;
  @Input() filter: boolean;

  constructor(private dialogService: TuiDialogService) {}

  openInfoDialog() {
    if (this.field?.infoDialogTemplate) {
      this.dialogService
        .open(this.field.infoDialogTemplate, { size: 's', dismissible: true })
        .subscribe();
    }
  }
}
