import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RoleType } from '../interfaces/role.model';
import { UserService } from '../services/user.service';

@Injectable()
export class NotAuthenticationGuard implements CanActivate {
  constructor(
    private authService: NbAuthService,
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuthenticatedOrRefresh().pipe(
      map((authenticated: boolean) => {
        if (authenticated) {
          const { role } = this.userService.userRoleInfo;

          let type = '';

          switch (role) {
            case RoleType.DELEGATE:
              type = 'delegato';
              break;

            case RoleType.COMPANY:
              type = 'azienda';
              break;

            case RoleType.WORKER:
              type = 'candidato';
              break;
          }

          this.router.navigate(['/', 'private', type]);
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
}
