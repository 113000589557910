import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { ToggleInput } from '../../../models/inputs/toggle-input';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseInput } from '../../../models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-toggle-input',
  styleUrls: ['./toggle-input.component.scss'],
  template: `
    <div
      #input
      class="toggle-input"
      [ngClass]="{ checked: this.control.value }"
      [formGroup]="formGroup"
    >
      <app-input-label
        *ngIf="control.inline"
        [name]="name"
        [field]="field"
      ></app-input-label>
      <tui-toggle
        formControlName="input"
        size="l"
        (change)="onChange($event)"
        [ngClass]="{
          'tui-space_left-1': control.inline
        }"
      ></tui-toggle>
    </div>
  `,
})
export class ToggleInputComponent extends BaseInputComponent implements OnInit {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @Input() name: string;
  @Input('control') set _control (c: ToggleInput) {
    this.control = c;
    this.field = {
      label: this.i18n.instant(this.control.label),
      inputType: this.control.inputType,
      value: this.formGroup.get('input').value,
    } as BaseInput;
  }
  control: ToggleInput;
  field: BaseInput;

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    input: new UntypedFormControl(),
  });

  constructor(private i18n: TranslateService) {
    super();
  }

  ngOnInit(): void {
    if (this.control.value)
      this.formGroup.get('input').setValue(this.control.value);
    this.control.valueChanges.subscribe((value) => {
      this.formGroup.get('input').setValue(value);
      this.setClass(value);
    });
  }

  onChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value: boolean = inputElement.checked;
    this.control.valueChange(value.toString());
    this.setClass(value);
  }

  setClass(add: boolean) {
    const checked = 'checked';
    if (this.input.nativeElement.classList.contains(checked))
      this.input.nativeElement.classList.remove(checked);
    if (add) this.input.nativeElement.classList.add(checked);
  }
}
