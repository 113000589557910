import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ApiClientService as ApiClient} from 'src/app/api-client.service';

@Injectable()
export class DocumentsService {
  constructor(private apiClient: ApiClient) {}

  documentsToSign(): Observable<string> {
    return this.apiClient.request<string>('clientDocumentsToSign');
  }
  
}
