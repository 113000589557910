import {
  Component,
  Injector,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogService } from '@taiga-ui/core';
import { Company } from 'src/app/core/auth/interfaces/company.model';
import { RoleType, UserType } from 'src/app/core/auth/interfaces/role.model';
import { Worker, WorkerStatus } from 'src/app/core/auth/interfaces/worker.model';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { SignatureConfirmComponent } from '../signature-confirm/signature-confirm.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserProfileService } from 'src/app/pages/private/pages/company/pages/user/services/user-profile.service';
import { UserWorkerProfileService } from 'src/app/pages/private/pages/worker/pages/user/services/user-worker-profile.service';
import { SignatureState } from 'src/app/pages/private/pages/worker/pages/messages/interfaces/final-confirm-info';
import { StatoEnum } from 'src/app/core/auth/enum/stato.enum';

@UntilDestroy()
@Component({
  selector: 'app-profile-progress-section',
  templateUrl: './profile-progress-section.component.html',
  styleUrls: ['./profile-progress-section.component.scss'],
})
export class ProfileProgressSectionComponent implements OnInit {
  @ViewChild('infoDialogTemplate') infoDialogTemplate: TemplateRef<any>;
  @Input() userType: UserType;
  @Input('user') set _user(user: Worker | Company) {
    this._setInfo(user);
    this._calculateCompleteDeg(user.completitionPercentage);
  }
  user: Worker | Company;
  percentageComplete: number;
  percentageCompleteInDeg: number;
  percentageTodoInDeg: number;
  chart: any;

  userSignature: string;
  userStatus: StatoEnum | WorkerStatus;
  phoneNumber: string;
  showGoToSignatureBtn: boolean;
  showCompleteProfileBtn: boolean;

  get UserTypeEnum() {
    return UserType;
  }
  get SignatureStateEnum() {
    return SignatureState;
  }
  get StatoEnum() {
    return StatoEnum;
  }

  constructor(
    private dialogService: TuiDialogService,
    private router: Router,
    private i18n: TranslateService,
    private injector: Injector,
    private loading: NgxSpinnerService,
    private workerProfileService: UserWorkerProfileService,
    private userProfileService: UserProfileService
  ) { }

  ngOnInit(): void { }

  openInfoDialog() {
    this.dialogService
      .open(this.infoDialogTemplate, { size: 's', dismissible: true })
      .subscribe();
  }

  onGoToCompleteProfile = () => {
    if( this.userType === UserType.COMPANY){
      this.router.navigate(['private', this.userType, 'utente', 'edit-profile']);
    } else {
      this.router.navigate(['private', this.userType, 'utente', 'user-profile']);
    }
  };

  onGoToSignature(content: TemplateRef<any>) {
    this.dialogService
      .open(
        new PolymorpheusComponent(SignatureConfirmComponent, this.injector),
        {
          dismissible: false,
          data: this.phoneNumber,
          size: 's',
          label: this.i18n.instant(
            'PRIVATE.COMPANY.USER.USER_PROFILE.INFO.DIALOG_SIGNATURE.TITLE'
          ),
        }
      )
      .subscribe((confirm: any) => {
        if (!confirm) {
          return;
        }
        this.loading.show();
        const addSignatureFunction = this.userType === UserType.WORKER
          ? this.workerProfileService.addWorkerSignature.bind(this.workerProfileService)
          : this.userProfileService.addClientSignature.bind(this.userProfileService)
        addSignatureFunction().pipe(
          catchError((err) => {
            this.loading.hide();
            return throwError(err);
          }),
          untilDestroyed(this)
        )
          .subscribe(() => {
            this.userProfileService.updateProfileInfo()
              .pipe(
                finalize(() => this.loading.hide()),
                untilDestroyed(this)
              )
              .subscribe(() =>
                this.dialogService
                  .open(content, {
                    dismissible: false,
                    size: 's',
                    label: this.i18n.instant(
                      'PRIVATE.COMPANY.USER.USER_PROFILE.INFO.DIALOG_SIGNATURE.TITLE'
                    ),
                  })
                  .subscribe()
              );
          });
      });
  }

  private _calculateCompleteDeg(complated: number) {
    this.percentageComplete = complated;
    this.percentageCompleteInDeg = (complated / 100) * 360;
    this.percentageTodoInDeg = 360 - this.percentageCompleteInDeg;
  }

  private _setInfo(user: Worker | Company) {
    this.user = user;
    if ('signature' in user) {
      this.userSignature = user.signature;
    }
    if ('digitalSignature' in user) {
      this.userSignature = user.digitalSignature;
    }
    if ('status' in user) {
      this.userStatus = user.status;
    }
    if ('userStatus' in user) {
      this.userStatus = user.userStatus;
    }
    if ('numeroTelefono' in user) {
      this.phoneNumber = user.numeroTelefono;
    }
    if ('telefono' in user) {
      this.phoneNumber = user.telefono;
    }
    if (this.userType == UserType.WORKER) {
      // this.showGoToSignatureBtn = (user as Worker).signature === SignatureState.FALSE && (user as Worker).status == StatoEnum.COMPLETO && (user as Worker).activateDigitalSignature;
      // this.showCompleteProfileBtn = (user as Worker).status == StatoEnum.INCOMPLETO;
    } else {
      this.showGoToSignatureBtn = (user as Company).digitalSignature === SignatureState.FALSE && (user as Company).userStatus === StatoEnum.COMPLETO && (user as Company).userRole === RoleType.COMPANY;
      this.showCompleteProfileBtn = (user as Company).userStatus == StatoEnum.INCOMPLETO;
    }
  }
}
