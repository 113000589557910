import { AfterViewInit, Component } from '@angular/core';

interface HowItWorksStep {
  number: number;
  tag: string;
  title: string;
  subtitle: string;
  text: string;
}

@Component({
  selector: 'app-homepage-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
})
export class HowItWorksComponent implements AfterViewInit {
  steps: HowItWorksStep[] = [
    // text & title = innerHTML (because of <b> & <br> tags)
    {
      number: 1,
      tag: 'HOME.HOW_IT_WORKS.STEP_1.TAG',
      title: 'HOME.HOW_IT_WORKS.STEP_1.TITLE',
      subtitle: 'HOME.HOW_IT_WORKS.STEP_1.SUBTITLE',
      text: 'HOME.HOW_IT_WORKS.STEP_1.TEXT',
    },
    {
      number: 2,
      tag: 'HOME.HOW_IT_WORKS.STEP_2.TAG',
      title: 'HOME.HOW_IT_WORKS.STEP_2.TITLE',
      subtitle: 'HOME.HOW_IT_WORKS.STEP_2.SUBTITLE',
      text: 'HOME.HOW_IT_WORKS.STEP_2.TEXT',
    },
    {
      number: 3,
      tag: 'HOME.HOW_IT_WORKS.STEP_3.TAG',
      title: 'HOME.HOW_IT_WORKS.STEP_3.TITLE',
      subtitle: 'HOME.HOW_IT_WORKS.STEP_3.SUBTITLE',
      text: 'HOME.HOW_IT_WORKS.STEP_3.TEXT',
    },
  ];
  curentStep: number = 1;

  constructor() {}

  ngAfterViewInit(): void {
    this._setScrollAttributes();
  }

  nextStep() {
    if (this.curentStep == this.steps.length) {
      this.curentStep = 1;
    } else {
      this.curentStep = this.curentStep + 1;
    }
    // scroll numbers
    var numbersContainerHeight = document.getElementById(
      'hiw-steps-container'
    ).clientHeight;
    document.getElementById('hiw-steps').style.marginTop =
      -((this.curentStep - 1) * numbersContainerHeight) + 'px';
    // show cards
    const visible = 'visible';
    const height = 'min-height';
    this.steps.forEach((step) => {
      var cardElement = document.getElementById('hiw-card-' + step.number);
      if (this.curentStep == 1) {
        if (cardElement.classList.contains(visible))
          cardElement.classList.remove(visible);
      } else {
        if (
          this.curentStep == step.number &&
          !cardElement.classList.contains(visible)
        )
          cardElement.classList.add(visible);
      }
    });
    // adjust container height
    const cardsContainer = document.getElementById('hiw-cards');
    if (this.curentStep == 1) {
      if (!cardsContainer.classList.contains(height))
        cardsContainer.classList.add(height);
    } else {
      if (cardsContainer.classList.contains(height))
        cardsContainer.classList.remove(height);
    }
  }

  private _setScrollAttributes() {
    const scrollContainers: Element[] = Array.from(
      document.getElementsByTagName('tui-scrollbar')
    );
    scrollContainers.forEach((scrollContainer) => {
      this._setInitScrollAttrs(scrollContainer as HTMLDivElement);
      scrollContainer.addEventListener('scroll', (e) => {
        this._handleScroll(scrollContainer as HTMLDivElement);
      });
    });
    window.addEventListener('resize', (e) => {
      scrollContainers.forEach((scrollContainer) => {
        this._handleScroll(scrollContainer as HTMLDivElement);
      });
    });
  }

  private _setInitScrollAttrs(scrollContainer: HTMLDivElement) {
    var scrollTop = 0;
    var elementScrollMax: number =
      scrollContainer.scrollHeight - scrollContainer.clientHeight;
    var scrollBottom = elementScrollMax - scrollTop;
    scrollContainer.setAttribute('topScroll', scrollTop.toString());
    scrollContainer.setAttribute('bottomScroll', scrollBottom.toString());
  }
  private _handleScroll(scrollContainer: HTMLDivElement) {
    var scrollTop = scrollContainer.scrollTop;
    var elementScrollMax: number =
      scrollContainer.scrollHeight - scrollContainer.clientHeight;
    var scrollBottom = elementScrollMax - scrollTop;
    if (scrollBottom.toString().startsWith('0.')) scrollBottom = 0;
    scrollContainer.setAttribute('topScroll', scrollTop.toString());
    scrollContainer.setAttribute('bottomScroll', scrollBottom.toString());
  }
}
